<template>
  <div class="zhaobiao">
      <gxjyheader title="全国招标信息查询"  desc=" "></gxjyheader>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item >全国招标信息查询</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-form ref="form" :model="form" style="background: #fff;margin: 20px 0;padding:10px 0" label-width="117px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="标题" >
                <el-input placeholder="请输入"   style="width: 218px" v-model="form.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属区域">
                <avue-cascader placeholder="请选择"   :checkStrictly="true" lazy :lazy-load="lazyLoad" :props="props" v-model="form.cascader" ></avue-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
<!--              <el-form-item label="采购类型">-->
<!--                <el-select v-model="form.categoryId" placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in getTypeList"-->
<!--                      :key="item.id"-->
<!--                      :label="item.title"-->
<!--                      :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <avue-search :option="option"   @change="loadlist(true)"
                           v-model="form">
              </avue-search>
            </el-col>
<!--            <el-col :span="8">-->
<!--              <el-form-item label="行业">-->
<!--                <el-select v-model="form.industryId" placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in getIndustryList"-->
<!--                      :key="item.id"-->
<!--                      :label="item.name"-->
<!--                      :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-form-item>
            <el-button type="primary" size="small" @click="loadlist(true)">筛选</el-button>
            <el-button @click="reset" size="small" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="lists">
        <div class="list flex" v-for="(item,index) in items" @click="gotoxq(item)" :key="index">
          <div class="flex-1">
            <div class="title"><span class="biao">【招标{{item.categoryName}}】</span>{{item.title}}</div>
            <div class="time"><span class="st1" :class="{red:item.state=='进行中'}">{{item.state!='进行中'?item.state:'剩余'+item.days+'天'}}</span></div>
            <div class="address">{{item.provinceName}}{{item.cityName}}{{item.areaName}}<span class="line"></span>{{item.industryName}}
            <span  style="float: right">{{item.createTime}}</span>
            </div>
          </div>
<!--           <div class="button">-->
<!--             <el-button type="primary" size="large" @click.stop.native="zixun(item)">我要咨询</el-button></div>-->
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="发送" width="600px" append-to-body :visible.sync="dialogTableVisible">
        <el-form ref="form" :model="shen" label-width="120px" :rules="rules">
          <el-form-item label="咨询详情" prop="detail">
            <el-input rows="5"  style="width: 300px" type="textarea" v-model="shen.detail"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input   v-model="shen.contacts"></el-input>
          </el-form-item>
          <el-form-item label="手机号"   prop="phone">
            <el-input  v-model.number="shen.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin-top: 20px" @click="submitForm()">保存</el-button>
            <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import gxjyheader from './gxjyheader'
import {getIndustryList, getTypeList, submitconsult, tenderinglist} from "../../request/moudle/demand";
export default {
  name: "zhaobiao",
  data(){
    return {
      form:{},
      option: {
        column: [{
          label: '采购类型',
          dicData:[],
          prop: 'categoryId',
          multiple: false,
          props: {
            label: 'title',
            value: 'id'
          },
        },{
          label: '行业',
          prop: 'industryId',
          props: {
            label: 'name',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        }
        ]
      },
      shen:{},
      zxid:'',
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },{ type: 'number', message: '手机号必须为数字值'}
        ]
      },
      dialogTableVisible:false,
      getIndustryList:[],
      getTypeList:[],
      props: {
        label: 'name',
        value: 'code'
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      items:[],
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    submitForm(){
      this.shen.tenderingBiddingId=this.zxid
      this.$api.demand.submitconsult(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },
    zixun(item){
      this.shen={}
      this.zxid=item.id
      this.dialogTableVisible=true
    },
    gotoxq(item){
      this.$router.push({
        path:'/allzhaobiaoxq',
        query:{
          id:item.id,
        }
      })
    },
    loadconfig(){
      this.$api.demand.getIndustryList().then((res)=>{
        this.getIndustryList=res.data
        const carTypeId=this.findObject(this.option.column, 'industryId')
        carTypeId.dicData=this.getIndustryList
        carTypeId.dicData.unshift({ name: '不限', id: '' })
      })
      this.$api.demand.getTypeList().then((res)=>{
        this.getTypeList=res.data
        const type=this.findObject(this.option.column, 'categoryId')
        type.dicData=this.getTypeList
        type.dicData.unshift({ title: '不限', id: '' })
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      if(this.form.cascader){
        this.form.province=this.form.cascader[0]
        this.form.city=this.form.cascader[1]
        this.form.area=this.form.cascader[2]
      }
      const obj={
        ...this.form
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.index.biddinglist(obj).then((res)=>{
        this.items=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
      this.form.title=''
      this.form.cascader=[]
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    lazyLoad(node, resolve) {
      let stop_level = 2;
      let level = node.level;
      let data = node.data || {}
      let code = data.code;
      let list = [];
      let callback = () => {
        resolve((list || []).map(ele => {
          return Object.assign(ele, {
            leaf: level >= stop_level
          })
        }));
      }
      if (level == 0) {
        this.$api.index.lazylist({parentCode:'00'}).then(res => {
          list = res.data;
          callback()
        });
      } else if (level == 1) {
        this.$api.index.lazylist({parentCode:code}).then(res => {
          list = res.data;
          callback()
        });
      } else if (level == 2) {
        this.$api.index.lazylist({parentCode:code}).then(res => {
          list = res.data;
          callback()
        });
      } else {
        callback()
      }
    }
  },
  components:{
    gxjyheader
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-input{
  width: auto;
}
.zhaobiao{
  background: #F5F5F5;

}
  .content{

    width: 1200px;
    margin: 15px auto;
    .sxx{
      background:#FFFFFF;
      padding:30px  40px;
      margin-top: 25px;
    }
    .fenye{
      text-align: center;
      padding: 30px 0;
      background: #fff;
    }
    .lists{
      background: #fff;
      padding: 15px 30px;
      font-size: 14px;
      color: #292929;
      line-height: 30px;
      .list{
        border-top: 1px solid #D1D1D1;
        padding: 15px 0;
      }
      .title{
        font-size: 16px;
        font-weight: 500;
        color: #000000;
      }
      .time{
        text-align: right;
      }
      .red{
        color: red;
      }
      .biao{
        display: inline-block;
        color: #0078F2;
        text-align: center;
        line-height: 24px;
        margin: 5px;
      }
      .line{
        border-left: 1px solid #292929;
        margin: 0 15px;
      }
      .button{
        text-align: center;
        margin: 15px 30px;
        margin-top: 30px;
      }
    }
  }
</style>